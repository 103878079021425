// Globals and polyfills.
// eslint-disable-next-line import/no-unused-modules
import "core-js/features/symbol";
import "core-js/features/array/from";
import "core-js/features/map";
import "./global/css";

import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { createRoot } from "react-dom/client";
import App from "./app";

createRoot(document.getElementById("root")).render(
  <Router>
    <App />
  </Router>,
);
