import React from "react";
import styled from "styled-components";
import { blue } from "../../global/colors.babel";
import Octopus from "./images/octopus";

const Four04 = () => (
  <main>
    <StyledOctopus />
    <Header>404</Header>
  </main>
);

const StyledOctopus = styled(Octopus)`
  display: block;
  margin: 64px auto 0;
`;

const Header = styled.h1`
  color: ${blue};
  font-size: 125px;
  font-weight: 600;
  line-height: 125px;
  margin-bottom: 24px;
  text-align: center;
`;

export default Four04;
