import React from "react";
import styled from "styled-components";
import { gray, lightGray } from "../../global/colors.babel";
import InstagramIcon from "./images/instagram-icon";

const Footer = () => (
  <StyledFooter>
    <List>
      <li>
        <A
          href="https://www.instagram.com/crazygilded/"
          aria-label="Follow us on Instagram at @crazygilded"
        >
          <StyledInstagramIcon />
          @crazygilded
        </A>
      </li>
      <li>
        <A
          href="https://www.instagram.com/explore/tags/chiangaingshebang/"
          aria-label="Explore the chiangaingshebang hashtag on Instagram"
        >
          #chiangaingshebang
        </A>
      </li>
    </List>
  </StyledFooter>
);

const StyledFooter = styled.footer`
  margin-bottom: 24px;
  padding-bottom: env(safe-area-inset-bottom);

  @media (min-width: 670px) {
    margin-bottom: 32px;
  }
`;

const List = styled.ul`
  align-items: center;
  color: ${lightGray};
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 20px;
  text-size-adjust: 100%;

  @media (min-width: 670px) {
    font-size: 14px;
    line-height: 24px;
  }
`;

const A = styled.a`
  color: ${lightGray};

  &:hover {
    color: ${gray};
  }
`;

const StyledInstagramIcon = styled(InstagramIcon)`
  display: block;
  margin: 0 auto 4px;

  /* stylelint-disable-next-line */
  ${A}:hover & {
    fill: ${gray};
  }
`;

export default Footer;
