import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import BaseH2 from "../../components/h2";
import BaseH3 from "../../components/h3";
import LinkSpan from "../../components/span";
import ListItem from "../../components/li";
import { GridCard } from "../../components/card";
import { Image, ImageRatioFixer } from "../../components/img";

const AccommodationsCard = ({
  imageUrl,
  name,
  area,
  address,
  mapsUrl,
  distance,
  directionsUrl,
  price,
  blurb,
}) => (
  <Card>
    <HotelImageRatioFixer>
      <Image src={imageUrl} alt="" />
    </HotelImageRatioFixer>
    <BaseH2>{name}</BaseH2>
    <BaseH3>{area}</BaseH3>
    <Ul>
      <a href={mapsUrl}>
        <ListItem>
          <strong>Location:</strong> <LinkSpan>{address}</LinkSpan>
        </ListItem>
      </a>
      <a href={directionsUrl}>
        <ListItem>
          <strong>Distance:</strong> <LinkSpan>{distance}</LinkSpan>
        </ListItem>
      </a>
      <ListItem>
        <strong>Price:</strong> {price}
      </ListItem>
    </Ul>
    {blurb}
  </Card>
);

AccommodationsCard.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  area: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  mapsUrl: PropTypes.string.isRequired,
  distance: PropTypes.string.isRequired,
  directionsUrl: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  blurb: PropTypes.node.isRequired,
};

const Card = styled(GridCard)`
  position: relative;
  padding: 24px;
`;

const HotelImageRatioFixer = styled(ImageRatioFixer)`
  margin: -24px -24px 24px;
  padding-bottom: calc((100% + 48px) * 0.4344);
  width: calc(100% + 48px);
`;

const Ul = styled.ul`
  margin: 8px 0;
`;

export default AccommodationsCard;
