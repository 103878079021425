import React from "react";
import styled from "styled-components";
import UnderlinedA from "../../components/a";
import { BaseP } from "../../components/p";
import crownePlaza from "./images/crowne-plaza.jpg";
import hayesValleyInn from "./images/hayes-valley-inn.jpg";
import hotelDelSol from "./images/hotel-del-sol.jpg";
import theMetroHotel from "./images/the-metro-hotel.jpg";

export const ACCOMMODATIONS_HEADER = {
  title: "Accommodations",
  blurb:
    "Because the Bay Area is an especially wonderful place to visit in " +
    "September, we recommend quickly securing a place to stay, whether it’s " +
    "in a hotel, at an Airbnb, or on a friend’s couch. Listed below are four " +
    "nearby—or, in the case of the Crowne Plaza, nearby-ish—hotels.",
};

const NoteP = styled(BaseP)`
  margin-bottom: 8px;
`;

export const ACCOMMODATIONS_DATA = [
  {
    id: 1,
    imageUrl: `${crownePlaza}`,
    name: "Crowne Plaza Foster\u00A0City",
    area: "Peninsula",
    address: "1221 Chess Drive, Foster\u00A0City",
    mapsUrl: "https://goo.gl/maps/hfDDCTf7nRN2",
    distance: "1 hour drive",
    directionsUrl: "https://goo.gl/maps/Zm8NwVXqUD22",
    price: "$",
    blurb: (
      <BaseP>
        Foster City is, frankly, not very exciting, but some of the Bay Area’s
        best{" "}
        <UnderlinedA href="https://www.yelp.com/biz/ramen-dojo-san-mateo">
          ramen
        </UnderlinedA>{" "}
        and{" "}
        <UnderlinedA href="https://www.yelp.com/biz/boba-guys-san-carlos-2">
          bubble tea
        </UnderlinedA>{" "}
        are closeby. Some of Randa’s extended family is staying here and would
        be pleased to have you join them. Rooms here, as you might expect, are
        noticeably more spacious than those at any of our other recommended
        hotels.
      </BaseP>
    ),
  },
  {
    id: 2,
    imageUrl: `${theMetroHotel}`,
    name: "The Metro Hotel",
    area: "San Francisco",
    address: "319 Divisadero Street",
    mapsUrl: "https://goo.gl/maps/QP8RaGFiZy72",
    distance: "30 minute drive",
    directionsUrl: "https://goo.gl/maps/5F1gvSHY8nu",
    price: "$$",
    blurb: (
      <>
        <NoteP>
          <strong>Note:</strong> This hotel doesn’t have an elevator.
        </NoteP>
        <BaseP>
          The Painted Ladies and the newly-renovated park next to the Painted
          Ladies are just a few blocks away. Drop into the{" "}
          <UnderlinedA href="http://www.nopalitosf.com/">
            restaurant
          </UnderlinedA>{" "}
          that is catering our wedding for a drink or two before heading to what
          might be the world’s most expensive (but worth it!){" "}
          <UnderlinedA href="http://www.biritemarket.com/">
            grocery store
          </UnderlinedA>
          .
        </BaseP>
      </>
    ),
  },
  {
    id: 3,
    imageUrl: `${hayesValleyInn}`,
    name: "Hayes Valley Inn",
    area: "San Francisco",
    address: "417 Gough Street",
    mapsUrl: "https://goo.gl/maps/dtsotBtvCdA2",
    distance: "20 minute drive",
    directionsUrl: "https://goo.gl/maps/Sz9sbfnz6a92",
    price: "$$",
    blurb: (
      <>
        <NoteP>
          <strong>Note:</strong> This hotel doesn’t have an elevator.
        </NoteP>
        <BaseP>
          Public transportation fans can take the{" "}
          <UnderlinedA href="https://www.sfmta.com/routes/49-van-nessmission">
            49 bus
          </UnderlinedA>{" "}
          almost directly to the wedding venue. Be sure to explore one of Hayes
          Valley’s specialties: the{" "}
          <UnderlinedA href="http://makerandmoss.com/">
            fancy furniture store
          </UnderlinedA>
          . Looking for something to do on Friday? Spend the night with the{" "}
          <UnderlinedA href="https://www.sfsymphony.org/Buy-Tickets/2018-19/MTT-conducts-Stravinsky-The-Firebird.aspx">
            San Francisco Symphony
          </UnderlinedA>
          .
        </BaseP>
      </>
    ),
  },
  {
    id: 4,
    imageUrl: `${hotelDelSol}`,
    name: "Hotel Del Sol",
    area: "San Francisco",
    address: "3100 Webster Street",
    mapsUrl: "https://goo.gl/maps/JU1cq1UYaUv",
    distance: "10 minute walk",
    directionsUrl: "https://goo.gl/maps/SHhTUDxeSbr",
    price: "$$",
    blurb: (
      <BaseP>
        Wake up each morning with help from one of the city’s “essential”{" "}
        <UnderlinedA href="https://www.wreckingballcoffee.com/">
          coffee shops
        </UnderlinedA>
        . Everything else on Union Street is centered around exercise, so walk
        around the block to{" "}
        <UnderlinedA href="http://www.roosterandrice.com/">
          Rooster & Rice
        </UnderlinedA>
        : our favorite place in the Marina. Carve out an afternoon to bike
        across the Golden Gate Bridge—it’s only one (sizable) hill away.
      </BaseP>
    ),
  },
];
