import styled from "styled-components";
import { superLightGray } from "../global/colors.babel";

export const Image = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  max-width: inherit;
  width: 100%;
`;

export const ImageRatioFixer = styled.div`
  position: relative;

  /* Fill in the bottom padding according to the image's aspect ratio. */
  background-color: ${superLightGray};
  display: block;
  height: 0;
  overflow: hidden;
  width: 100%;
`;
