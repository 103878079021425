import styled from "styled-components";
import { darkGray } from "../global/colors.babel";

const ListItem = styled.li`
  color: ${darkGray};
  font-size: 16px;
  line-height: 28px;
`;

export default ListItem;
