import React from "react";
import { Route, Routes } from "react-router-dom";
import GlobalStyle from "../global/css";
import Accommodations from "../pages/accommodations";
import Activities from "../pages/activities";
import Home from "../pages/home";
import Schedule from "../pages/schedule";
import Four04 from "../pages/404";
import Header from "./header";

const App = () => (
  <>
    <GlobalStyle />
    <Header />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/activities" element={<Activities />} />
      <Route path="/accommodations" element={<Accommodations />} />
      <Route path="/schedule" element={<Schedule />} />
      <Route path="*" element={<Four04 />} />
    </Routes>
  </>
);

export default App;
