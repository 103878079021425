import styled from "styled-components";

export const Grid = styled.section`
  margin: 24px auto 32px;
  max-width: 480px;

  @supports (display: grid) {
    @media (min-width: 670px) {
      display: grid;
      grid-gap: 16px;
      grid-template-columns: repeat(2, 1fr);
      justify-content: center;
      max-width: 976px;
      padding: 0 24px;
      padding-left: calc(24px + env(safe-area-inset-left));
      padding-right: calc(24px + env(safe-area-inset-right));
    }
  }
`;

export const GridWrapper = styled.div`
  padding: 0 16px;
  padding-left: calc(16px + env(safe-area-inset-left));
  padding-right: calc(16px + env(safe-area-inset-right));

  @supports (display: grid) {
    @media (min-width: 670px) {
      padding: 0;
    }
  }
`;
