import styled from "styled-components";
import { darkGray } from "../global/colors.babel";

const H3 = styled.h3`
  color: ${darkGray};
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.075em;
  margin: 4px 0;
`;

export default H3;
