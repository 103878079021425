import React from "react";
import styled from "styled-components";
import { lightGray } from "../../../global/colors.babel";

const Church = (props) => (
  <svg
    aria-hidden="true"
    fill="none"
    focusable="false"
    stroke={lightGray}
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeMiterlimit={10}
    viewBox="0 0 58 99"
    {...props}
  >
    <g strokeWidth={1.5}>
      <path d="M39.502 61.099l17.549-.011v37.054H.789V61.088h17.549" />
      <path d="M18.338 58.808h21.164v39.334H18.338zM11.211 61.088v37.054M46.932 61.088v37.054M8.045 69.718c0-1.212-1.069-3.225-2.107-3.481-1.091.378-2.109 2.269-2.109 3.481v4.714h4.216v-4.714zM18.338 69.718c0-1.212-1.069-3.225-2.107-3.481-1.091.378-2.108 2.269-2.108 3.481v4.714h4.215v-4.714zM8.045 82.379c0-1.212-1.069-3.225-2.107-3.481-1.091.378-2.109 2.269-2.109 3.481v4.714h4.216v-4.714zM18.338 82.379c0-1.212-1.069-3.225-2.107-3.481-1.091.378-2.108 2.269-2.108 3.481v4.714h4.215v-4.714zM43.717 69.718c0-1.212-1.068-3.225-2.107-3.481-1.09.378-2.108 2.269-2.108 3.481v4.714h4.215v-4.714zM54.392 69.718c0-1.212-1.068-3.225-2.107-3.481-1.09.378-2.108 2.269-2.108 3.481v4.714h4.215v-4.714zM43.717 82.379c0-1.212-1.068-3.225-2.107-3.481-1.09.378-2.108 2.269-2.108 3.481v4.714h4.215v-4.714zM54.392 82.379c0-1.212-1.068-3.225-2.107-3.481-1.09.378-2.108 2.269-2.108 3.481v4.714h4.215v-4.714z" />
      <path d="M39.503 90.164c0-6.086-5.199-15.777-10.583-17.48-5.474 1.9-10.582 11.394-10.582 17.48" />
      <path d="M34.574 90.257c0-3.27-2.804-10.36-5.687-11.403-2.88 1.549-5.685 8.133-5.685 11.403" />
      <path d="M18.377 85.224l10.582-25.657 10.51 25.482M5.386 49.308h7.979v11.781H5.386zM9.185 33.221l-3.8 16.086h7.98l-4.18-16.086z" />
      <path d="M8.046 53.217h2.786v7.871H8.046zM8.045 57.152h2.786M44.082 49.308h7.98v11.781h-7.98zM47.881 33.221l-3.8 16.086h7.981l-4.181-16.086z" />
      <path d="M46.742 53.217h2.786v7.871h-2.786zM46.742 57.152h2.786M22.766 40.848h12.166v17.96H22.766zM28.631 4.923l-5.794 35.925h12.166L28.631 4.923z" />
      <path d="M26.893 46.808h4.248v12h-4.248zM26.893 52.808h4.248" />
      <path d="M23.202 90.17v7.972M34.588 90.17v7.972" />
      <path d="M23.582 89.497h10.612M28.888 98.141v-8.644" />
      <path d="M28.631 4.923V.792M26.565 2.857h4.132" />
      <path d="M28.799 26.761l-2.431 14.087h5.104l-2.673-14.087zM9.303 43.228l-1.437 6.08h3.017l-1.58-6.08zM48.063 43.228l-1.437 6.08h3.017l-1.58-6.08zM18.338 44.648l4.428-3.8v17.96h-4.428v-14.16zM39.502 44.648l-4.428-3.8v17.96h4.428v-14.16zM3.83 69.718h4.216M14.124 69.718h4.215M3.83 82.589h4.216M14.124 82.589h4.215M39.488 69.718h4.215M50.335 69.718h3.834M39.488 82.589h4.215M50.335 82.589h3.834" />
    </g>
    <g strokeWidth={1.2}>
      <path d="M37.141 90.17c0-4.732-4.167-12.616-8.228-14.351-4.255 1.478-8.227 9.619-8.227 14.351" />
      <path d="M20.686 90.17v7.972" />
      <path d="M37.141 90.17v7.972" />
      <path d="M18.188 44.824V35.55l2.309 7.197.711-14.973 1.558 13.073M39.648 44.824V35.55l-2.309 7.197-.712-14.973-1.558 13.073" />
    </g>
    <g fill={lightGray} strokeWidth={0}>
      <circle cx={31} cy={95} r={0.75} />
      <circle cx={26.75} cy={95} r={0.75} />
    </g>
  </svg>
);

const SizedChurch = styled(Church)`
  width: 105px;

  /* Target Internet Explorer versions 10 and 11. */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    height: 179px;
  }
`;

export default SizedChurch;
