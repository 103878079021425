import React, { Fragment } from "react";
import styled from "styled-components";
import { PageCard } from "../../components/card";
import { lightGray } from "../../global/colors.babel";
import BorderLeft from "./images/border-left";
import BorderRight from "./images/border-right";
import Event from "./event";
import { SCHEDULE_DATA } from "./data";

const Card = () => (
  <ScheduleCardWrapper>
    <ScheduleCard>
      {SCHEDULE_DATA.map((event, index) => (
        <Fragment key={event.id}>
          <Event {...event} />
          {index !== SCHEDULE_DATA.length - 1 && <HorizontalRule />}
        </Fragment>
      ))}
      <SVGWrapper>
        <SizedBorderLeft />
        <SizedBorderRight />
      </SVGWrapper>
    </ScheduleCard>
  </ScheduleCardWrapper>
);

const ScheduleCardWrapper = styled.div`
  margin-bottom: 32px;
  padding: 0 16px;

  @media (min-width: 375px) {
    padding: 0 32px;
  }
`;

const ScheduleCard = styled(PageCard)`
  position: relative;
  max-width: 448px;
  padding: 48px;

  @media (min-width: 540px) {
    padding: 64px 128px;
  }

  @media (min-width: 670px) {
    padding: 80px 160px;
  }

  /* Target Internet Explorer versions 10 and 11. */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    padding: 48px;
  }
`;

const SVGWrapper = styled.div`
  position: absolute;
  display: none;
  height: 100%;
  top: 0;

  @media (min-width: 540px) {
    left: 24px;
    display: block;
    width: calc(100% - 48px);
  }

  @media (min-width: 670px) {
    left: 48px;
    width: calc(100% - 96px);
  }

  /* Target Internet Explorer versions 10 and 11. */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    left: 0;
    display: none;
    width: 100%;
  }
`;

const SizedBorderLeft = styled(BorderLeft)`
  float: left;
  height: 100%;
`;

const SizedBorderRight = styled(BorderRight)`
  float: right;
  height: 100%;
`;

const HorizontalRule = styled.hr`
  background-color: ${lightGray};
  margin: 32px auto;
  width: 128px;
`;

export default Card;
