import React from "react";
import styled from "styled-components";
import Footer from "../../app/footer";
import Header from "./header";
import Illustration from "./images/illustration";

const Home = () => (
  <>
    <main>
      <Header />
      <IllustrationWithMargin />
    </main>
    <Footer />
  </>
);

const IllustrationWithMargin = styled(Illustration)`
  margin-top: -16px;
  pointer-events: none;

  @media (min-width: 375px) {
    margin-top: -32px;
  }

  @media (min-width: 670px) {
    margin-top: -64px;
  }

  @media (min-width: 880px) {
    margin-top: -96px;
  }
`;

export default Home;
