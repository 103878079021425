import React from "react";
import { lightGray } from "../../../global/colors.babel";

const BorderLeft = (props) => (
  <svg
    aria-hidden="true"
    fill="none"
    focusable="false"
    stroke={lightGray}
    strokeLinecap="round"
    strokeMiterlimit={10}
    viewBox="0 0 40 310"
    {...props}
  >
    <path d="M12.773 83.54V48.811a4 4 0 0 1 4-4h15.19a5 5 0 0 0 5-5V1.125M25.631 29.003h11.332M23.127 29.207c0 .946-.767 1.713-1.713 1.713M14.676 87.753a1.714 1.714 0 1 0-3.427.001 1.714 1.714 0 0 0 3.427-.001zM6.463 140.04a1.714 1.714 0 1 0-3.427.001 1.714 1.714 0 0 0 3.427-.001z" />
    <path d="M21.246 33.541v33.498a3.667 3.667 0 0 1-3.666 3.666H8.31a3.667 3.667 0 0 0-3.667 3.667v62.167M12.773 224.88v34.729a4 4 0 0 0 4 4h15.19a5 5 0 0 1 5 5v40.266M12.773 216.713V91.706M25.631 279.417h11.332M21.246 232.213V76.206M23.127 279.213c0-.946-.767-1.713-1.713-1.713M14.676 220.668a1.714 1.714 0 1 0-3.427.001 1.714 1.714 0 0 0 3.427-.001zM6.463 168.38c0-.945-.768-1.713-1.713-1.713a1.714 1.714 0 1 0 1.713 1.713z" />
    <path d="M21.246 274.879v-33.498a3.667 3.667 0 0 0-3.666-3.667H8.31a3.667 3.667 0 0 1-3.667-3.667v-61.843M4.699 143.558v21.089" />
  </svg>
);

export default BorderLeft;
