import styled from "styled-components";

const Header = styled.header`
  margin: 24px 16px;
  text-align: center;

  @media (min-width: 670px) {
    margin: 48px 24px 32px;
  }
`;

export default Header;
