import React from "react";
import Footer from "../../app/footer";
import SectionHeader from "../../components/section-header";
import Body from "./body";
import PaintedLadies from "./images/painted-ladies";
import { ACCOMMODATIONS_HEADER } from "./data";

const Accommodations = () => (
  <>
    <main>
      <SectionHeader {...ACCOMMODATIONS_HEADER} svg={PaintedLadies} />
      <Body />
    </main>
    <Footer />
  </>
);

export default Accommodations;
