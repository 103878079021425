import PropTypes from "prop-types";
import React from "react";
import Header from "./header";
import { SectionHeaderP } from "./p";
import { SectionH1 } from "./h1";

const SectionHeader = ({ title, blurb, svg: Svg }) => (
  <Header>
    <SectionH1>{title}</SectionH1>
    <SectionHeaderP>{blurb}</SectionHeaderP>
    <Svg />
  </Header>
);

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  blurb: PropTypes.string.isRequired,
  svg: PropTypes.elementType.isRequired,
};

export default SectionHeader;
