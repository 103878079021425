const blue = "#0f7397";
const gray = "#9d9d9d";
const pink = "#f9a29c";
const lightBlue = "#4b96b1";
const lightGray = "#c6c5c4";
const lightPink = "#fabdb9";
const semiLightGray = "#aaaaaa";
const superLightGray = "#fafafa";
const darkGray = "#7d7d7d";
const superDarkGray = "#575352";

export {
  blue,
  gray,
  pink,
  lightBlue,
  lightGray,
  lightPink,
  semiLightGray,
  superLightGray,
  darkGray,
  superDarkGray,
};
