import React from "react";
import styled from "styled-components";
import Header from "../../components/header";
import { BaseH1 } from "../../components/h1";
import { pink } from "../../global/colors.babel";

const HomeHeader = () => (
  <Header>
    <BaseH1>Randa & Chrisna</BaseH1>
    <HomeH2>09.22.18</HomeH2>
  </Header>
);

const HomeH2 = styled.h2`
  color: ${pink};
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.15em;
  line-height: 35px;

  @media (min-width: 670px) {
    font-size: 24px;
    line-height: 42px;
  }

  @media (min-width: 880px) {
    font-size: 28px;
    line-height: 49px;
  }
`;

export default HomeHeader;
