import styled from "styled-components";
import { blue, lightBlue } from "../global/colors.babel";

const LinkSpan = styled.span`
  color: ${lightBlue};

  a:hover & {
    color: ${blue};
    text-decoration: underline;
  }
`;

export default LinkSpan;
