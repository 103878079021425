export const SCHEDULE_HEADER = {
  title: "Schedule",
  blurb:
    "Our wedding ceremony and reception will take place in the same space: Gallery 308 at Fort Mason. The afterparty will start just a few doors down at a bar called The Interval at Long Now. We can’t wait to celebrate with all of you!",
};

export const SCHEDULE_DATA = [
  {
    title: "Gallery 308 at Fort Mason",
    details: [
      "Ceremony starts at 4:00pm",
      "Cocktails and hors d’oeuvres",
      "Dinner reception",
      "Dancing",
      "Semi-formal attire",
    ],
    address: ["2 Marina Boulevard", "Landmark Building A", "San Francisco, CA"],
    id: 1,
  },
  {
    title: "The Interval at Long Now",
    details: ["Join us for drinks at 10:00pm"],
    address: ["2 Marina Boulevard", "Landmark Building A", "San Francisco, CA"],
    id: 2,
  },
];
