import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import H2 from "../../components/h2";
import BaseH3 from "../../components/h3";
import { BaseP as P } from "../../components/p";
import { GridCard } from "../../components/card";
import { lightPink } from "../../global/colors.babel";

const ActivitiesCard = ({ name, categories, locations, blurb, url }) => (
  <A href={url}>
    <Card>
      <ActivitiesH2>{name}</ActivitiesH2>
      <BaseH3>{locations.concat(categories).join(" • ")}</BaseH3>
      <P>{blurb}</P>
    </Card>
  </A>
);

ActivitiesCard.propTypes = {
  name: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  locations: PropTypes.arrayOf(PropTypes.string).isRequired,
  blurb: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

const A = styled.a`
  display: block;
  transition: transform 0.15s;

  &:hover {
    transform: translateY(-2px);
  }
`;

const Card = styled(GridCard)`
  box-sizing: border-box;
  height: 100%;
  text-align: center;
  transition: box-shadow 0.15s;

  /* stylelint-disable-next-line */
  ${A}:hover & {
    box-shadow: 0 4px 8px 1px rgb(0 0 0 / 10%);
  }
`;

const ActivitiesH2 = styled(H2)`
  transition: color 0.15s;

  /* stylelint-disable-next-line */
  ${A}:hover & {
    color: ${lightPink};
  }
`;

export default ActivitiesCard;
