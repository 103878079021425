import React from "react";
import Footer from "../../app/footer";
import SectionHeader from "../../components/section-header";
import Card from "./card";
import Church from "./images/church";
import { SCHEDULE_HEADER } from "./data";

const Schedule = () => (
  <>
    <main>
      <SectionHeader {...SCHEDULE_HEADER} svg={Church} />
      <Card />
    </main>
    <Footer />
  </>
);

export default Schedule;
