export const ACTIVITIES_HEADER = {
  title: "Activities",
  blurb:
    "San Francisco and its neighbors are full of engaging adventures, delicious food, and beautiful sights. If you’re short on ideas for how to wisely spend your non-wedding days in the Bay Area, we’ve gathered a bunch of our favorite things to do below.",
};

export const ACTIVITIES_DATA = [
  {
    id: 1,
    name: "Al’s Place",
    categories: ["Food"],
    locations: ["San Francisco"],
    blurb:
      "A seafood-focused restaurant on the quiet end of the Mission with delightfully plant-heavy decor.",
    url: "https://www.alsplacesf.com",
  },
  {
    id: 2,
    name: "Alamo Square Park",
    categories: ["Park"],
    locations: ["San Francisco"],
    blurb:
      "The newly renovated park located next to the iconic Painted Ladies.",
    url: "https://sfrecpark.org/Facilities/Facility/Details/Alamo-Square-323",
  },
  {
    id: 3,
    name: "Alcatraz",
    categories: ["Museum"],
    locations: ["San Francisco"],
    blurb:
      "The best super touristy thing to do in San Francisco, by far. Even the short ferry ride is a treat.",
    url: "https://www.nps.gov/alca/index.htm",
  },
  {
    id: 4,
    name: "Arizmendi Bakery",
    categories: ["Food"],
    locations: ["San Francisco"],
    blurb:
      "Each location is an independently operated, worker-owned cooperative. Swing by for the pizza of the day.",
    url: "https://www.arizmendibakery.com",
  },
  {
    id: 5,
    name: "b. patisserie",
    categories: ["Food"],
    locations: ["San Francisco"],
    blurb:
      "Our favorite bakery in San Francisco. Buy all of the kouign amann that you can possibly eat—we won’t judge.",
    url: "https://bpatisserie.com",
  },
  {
    id: 6,
    name: "Bernal Heights Park",
    categories: ["Park"],
    locations: ["San Francisco"],
    blurb:
      "The top of Bernal Hill offers some of the best possible views of San Francisco’s skyline.",
    url: "https://sfrecpark.org/Facilities/Facility/Details/Bernal-Heights-Park-151",
  },
  {
    id: 7,
    name: "Bi-Rite Creamery",
    categories: ["Food"],
    locations: ["San Francisco"],
    blurb:
      "Ice cream! We like the honey lavender, balsamic strawberry, and basil flavors, but why stop there?",
    url: "https://biritemarket.com/creamery",
  },
  {
    id: 8,
    name: "Boba Guys",
    categories: ["Food"],
    locations: ["San Francisco", "Peninsula"],
    blurb:
      "A bubble tea shop with long lines. Don’t be distracted by the snacks on display; you’re there for the drinks.",
    url: "https://www.bobaguys.com",
  },
  {
    id: 9,
    name: "Cala",
    categories: ["Food"],
    locations: ["San Francisco"],
    blurb:
      "Mexican food from one of Mexico City’s most famous chefs spiced down for the American palate.",
    url: "https://www.calarestaurant.com",
  },
  {
    id: 10,
    name: "California Academy of Sciences",
    categories: ["Museum"],
    locations: ["San Francisco"],
    blurb:
      "A combination aquarium, planetarium, and natural history museum underneath a gorgeous living roof.",
    url: "https://calacademy.org",
  },
  {
    id: 11,
    name: "Cotogna",
    categories: ["Food"],
    locations: ["San Francisco"],
    blurb:
      "Italian food with a Californian twist. Reservations strongly suggested for dinner.",
    url: "https://cotognasf.com/",
  },
  {
    id: 12,
    name: "de Young Museum",
    categories: ["Museum"],
    locations: ["San Francisco"],
    blurb:
      "A popular museum for art lovers with a (free!) observation tower for everybody else.",
    url: "https://deyoung.famsf.org",
  },
  {
    id: 13,
    name: "Del Popolo",
    categories: ["Food"],
    locations: ["San Francisco"],
    blurb:
      "A pizza place that started off as a food truck with its own 5,000-pound wood-burning oven.",
    url: "https://www.delpopolosf.com/",
  },
  {
    id: 14,
    name: "Dolores Park",
    categories: ["Park"],
    locations: ["San Francisco"],
    blurb: "The park that seemingly everyone frequents when it’s nice outside.",
    url: "https://sfrecpark.org/Facilities/Facility/Details/Mission-Dolores-Park-188",
  },
  {
    id: 15,
    name: "Exploratorium",
    categories: ["Museum"],
    locations: ["San Francisco"],
    blurb:
      "An interactive science museum for visitors of all ages along the Embarcadero.",
    url: "https://www.exploratorium.edu",
  },
  {
    id: 16,
    name: "Hot Sauce and Panko",
    categories: ["Food"],
    locations: ["San Francisco"],
    blurb:
      "Feeling brave? Try the ghost pepper chicken wings. Don’t worry, milder flavors exist for the weak.",
    url: "http://hotsauceandpanko.com",
  },
  {
    id: 17,
    name: "In Situ",
    categories: ["Food"],
    locations: ["San Francisco"],
    blurb: "Sample dishes from famous restaurants around the world!",
    url: "http://insitu.sfmoma.org",
  },
  {
    id: 18,
    name: "Joseph Phelps Vineyards",
    categories: ["Vineyard"],
    locations: ["Napa"],
    blurb:
      "A down-to-earth wine tasting experience on a terrace with magnificent views of their vineyard.",
    url: "https://www.josephphelps.com",
  },
  {
    id: 19,
    name: "Kin Khao",
    categories: ["Food"],
    locations: ["San Francisco"],
    blurb:
      "The better of the two Thai restaurants at the intersection of Mason and Ellis.",
    url: "http://www.kinkhao.com",
  },
  {
    id: 20,
    name: "La Taqueria",
    categories: ["Food"],
    locations: ["San Francisco"],
    blurb:
      "Amazing tacos dorados, burritos, and mango aguas frescas. Cash only.",
    url: "https://www.yelp.com/biz/la-taqueria-san-francisco-2",
  },
  {
    id: 21,
    name: "Liholiho Yacht Club",
    categories: ["Food"],
    locations: ["San Francisco"],
    blurb:
      "Fancy Hawaiian-ish food with a bar downstairs. Reservations are very difficult to come by; book in advance.",
    url: "https://liholihoyachtclub.com",
  },
  {
    id: 22,
    name: "Marlowe",
    categories: ["Food"],
    locations: ["San Francisco"],
    blurb:
      "Go for brunch, if you can! Once you’re inside, order the warm deviled eggs and brûléed citrus.",
    url: "https://www.marlowesf.com",
  },
  {
    id: 23,
    name: "Mensho Tokyo",
    categories: ["Food"],
    locations: ["San Francisco"],
    blurb:
      "Ramen worth standing in line for. Try our favorite: the vegan tantanmen with duck chashu.",
    url: "http://mensho.tokyo",
  },
  {
    id: 24,
    name: "Muir Woods",
    categories: ["Park"],
    locations: ["Marin County"],
    blurb:
      "Explore a redwood forest without the drive to the northern edge of California.",
    url: "https://www.nps.gov/muwo/index.htm",
  },
  {
    id: 25,
    name: "Pinnacles National Park",
    categories: ["Park"],
    locations: ["Central California"],
    blurb:
      "This is the closest national park to the Bay Area. It’s still pretty far away. Bring a flashlight.",
    url: "https://www.nps.gov/pinn/index.htm",
  },
  {
    id: 26,
    name: "Rooster & Rice",
    categories: ["Food"],
    locations: ["San Francisco"],
    blurb:
      "Comfort food from Thailand. A potential (but untested) post-wedding hangover cure.",
    url: "https://www.roosterandrice.com",
  },
  {
    id: 27,
    name: "Saigon Sandwich",
    categories: ["Food"],
    locations: ["San Francisco"],
    blurb:
      "A cheap, reliable option for bánh mì. No seating available, but City Hall is nearby. Cash only.",
    url: "https://www.yelp.com/biz/saigon-sandwich-san-francisco",
  },
  {
    id: 28,
    name: "SFMOMA",
    categories: ["Museum"],
    locations: ["San Francisco"],
    blurb:
      "The rooftop sculpture garden is a highlight in a museum full of interesting (and confusing) modern art.",
    url: "https://www.sfmoma.org",
  },
  {
    id: 29,
    name: "State Bird Provisions",
    categories: ["Food"],
    locations: ["San Francisco"],
    blurb:
      "Californian farm-to-table dim sum. If an ice cream sandwich is available for dessert, order it.",
    url: "https://statebirdsf.com/home",
  },
  {
    id: 30,
    name: "Sutro Baths/Lands End",
    categories: ["Park"],
    locations: ["San Francisco"],
    blurb:
      "Absolutely stunning in September on sunny days. Free parking is available in a gigantic, easy-to-find parking lot.",
    url: "https://www.nps.gov/goga/planyourvisit/cliff-house-sutro-baths.htm",
  },
  {
    id: 31,
    name: "Teaspoon",
    categories: ["Food"],
    locations: ["San Francisco", "Peninsula"],
    blurb:
      "Another bubble tea shop! Randa appreciates their delicious caffeine-free options.",
    url: "https://www.teaspoonlife.com",
  },
  {
    id: 32,
    name: "The Stanford Dish",
    categories: ["Park"],
    locations: ["Stanford"],
    blurb:
      "A hilly 3.5-mile loop for a serious walk, jog, or run with stunning views of the Bay Area. Should take 75–90 minutes.",
    url: "https://dish.stanford.edu",
  },
  {
    id: 33,
    name: "Volcano Curry",
    categories: ["Food"],
    locations: ["San Francisco"],
    blurb:
      "Our favorite Japanese curry place. Randa likes the extra pickles and Chrisna, the extra spicy curry.",
    url: "https://volcanocurry.blogspot.com",
  },
];
