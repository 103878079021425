import React from "react";
import { Grid, GridWrapper } from "../../components/grid";
import Card from "./card";
import { ACCOMMODATIONS_DATA } from "./data";

const AccommodationsBody = () => (
  <GridWrapper>
    <Grid>
      {ACCOMMODATIONS_DATA.map((hotel) => (
        <Card key={hotel.id} {...hotel} />
      ))}
    </Grid>
  </GridWrapper>
);

export default AccommodationsBody;
