import styled from "styled-components";
import { blue, lightBlue } from "../global/colors.babel";

const UnderlinedA = styled.a`
  color: ${lightBlue};

  &:hover {
    color: ${blue};
    text-decoration: underline;
  }
`;

export default UnderlinedA;
