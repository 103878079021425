import styled from "styled-components";
import { pink } from "../global/colors.babel";

const H2 = styled.h2`
  color: ${pink};
  font-size: 20px;
  letter-spacing: 0.1em;
  line-height: 28px;
  margin-bottom: 4px;
  text-transform: uppercase;
`;

export default H2;
