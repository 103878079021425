import React from "react";
import Footer from "../../app/footer";
import SectionHeader from "../../components/section-header";
import Body from "./body";
import CableCar from "./images/cable-car";
import { ACTIVITIES_HEADER } from "./data";

const Activities = () => (
  <>
    <main>
      <SectionHeader {...ACTIVITIES_HEADER} svg={CableCar} />
      <Body />
    </main>
    <Footer />
  </>
);

export default Activities;
