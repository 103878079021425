import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { CardH1 } from "../../components/h1";
import { gray, superDarkGray } from "../../global/colors.babel";

/* eslint-disable react/no-array-index-key */
const Event = ({ title, address, details }) => (
  <article>
    <EventH1>{title}</EventH1>
    <UnorderedList>
      {address.map((line, index) => (
        <AddressListItem key={index}>{line}</AddressListItem>
      ))}
    </UnorderedList>
    <UnorderedList>
      {details.map((line, index) => (
        <DetailsListItem key={index}>{line}</DetailsListItem>
      ))}
    </UnorderedList>
  </article>
);
/* eslint-enable react/no-array-index-key */

Event.propTypes = {
  title: PropTypes.string.isRequired,
  address: PropTypes.arrayOf(PropTypes.string).isRequired,
  details: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const EventH1 = styled(CardH1)`
  line-height: 24px;
  margin-bottom: 12px;
`;

const UnorderedList = styled.ul`
  margin: 8px 0;

  &:last-child {
    margin-bottom: 0;
  }
`;

const ListItem = styled.li`
  font-family: Butler, Georgia, serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

const AddressListItem = styled(ListItem)`
  color: ${gray};
`;

const DetailsListItem = styled(ListItem)`
  color: ${superDarkGray};
`;

export default Event;
