import { createGlobalStyle } from "styled-components";
import butlerWoff from "../../static/fonts/butler-medium.woff";
import butlerWoff2 from "../../static/fonts/butler-medium.woff2";
import poppinsBoldWoff from "../../static/fonts/poppins-bold.woff";
import poppinsBoldWoff2 from "../../static/fonts/poppins-bold.woff2";
import poppinsRegularWoff from "../../static/fonts/poppins-regular.woff";
import poppinsRegularWoff2 from "../../static/fonts/poppins-regular.woff2";
import poppinsSemiboldWoff from "../../static/fonts/poppins-semibold.woff";
import poppinsSemiboldWoff2 from "../../static/fonts/poppins-semibold.woff2";

const globalStyle = createGlobalStyle`
  @font-face {
    font-family: Butler;
    font-style: normal;
    font-weight: 500;
    src:
      url(${butlerWoff2}) format("woff2"),
      url(${butlerWoff}) format("woff");
  }

  @font-face {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    src:
      url(${poppinsRegularWoff2}) format("woff2"),
      url(${poppinsRegularWoff}) format("woff");
  }

  @font-face {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    src:
      url(${poppinsSemiboldWoff2}) format("woff2"),
      url(${poppinsSemiboldWoff}) format("woff");
  }

  @font-face {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    src:
      url(${poppinsBoldWoff2}) format("woff2"),
      url(${poppinsBoldWoff}) format("woff");
  }

  body,
  h1,
  h2,
  h3,
  p,
  ul {
    border: 0;
    font-family: Poppins, "Gill Sans", "Trebuchet MS", sans-serif;
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
  }

  body {
    word-wrap: break-word;
  }

  li {
    list-style: none;
  }

  strong {
    font-weight: 600;
  }

  /* https://accessibility.18f.gov/hidden-content/ */
  .visually-hidden {
    position: absolute;
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    width: 1px;
  }

  :root {
    -ms-overflow-style: none;
  }
`;

export default globalStyle;
